/* eslint-disable react/jsx-key */
import AuthorizedRoute from 'base-shell/lib/components/AuthorizedRoute/AuthorizedRoute'
import React, { lazy } from 'react'
import { Route } from 'react-router-dom'

const About = lazy(() => import('../pages/About'))
const Dashboard = lazy(() => import('../pages/Dashboard'))
const Karateka = lazy(() => import('../pages/Karateka'))

const routes = [
  <AuthorizedRoute path="/dashboard" exact component={Dashboard} />,
  <Route path="/about" exact component={About} />,
  <Route path="/karateka/:karatekaid" exact component={Karateka} />
]

export default routes
